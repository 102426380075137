import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [9,[2],[3]],
		"/(open)/audit/[companyId]/[requestId]": [~38,[7,8]],
		"/(open)/campagnes/[...firmId]": [39,[7]],
		"/(open)/cgu": [~40,[7]],
		"/(auth)/email/verify": [~32],
		"/(auth)/info": [~33],
		"/(app)/newsletters/history": [~30,[2],[3]],
		"/(open)/newsletters/[id]": [~41,[7]],
		"/(auth)/password/forgot": [34],
		"/(auth)/password/reset": [35],
		"/(app)/settings/preferences": [~31,[2],[3]],
		"/(auth)/signin": [~36],
		"/(auth)/signup": [~37],
		"/(app)/[companyId=guid]/aides": [~11,[2,4],[3,5]],
		"/(app)/[companyId=guid]/aides/[slug]": [~12,[2,4],[3,5]],
		"/(app)/[companyId=guid]/audit/[requestId]": [~13,[2,4],[3,5]],
		"/(app)/[companyId=guid]/files": [~14,[2,4],[3,5]],
		"/(app)/[companyId=guid]/newsletters/[...newsletterId]/results/[newsletterResultId]": [~16,[2,4],[3,5]],
		"/(app)/[companyId=guid]/newsletters/[...newsletterId]": [~15,[2,4],[3,5]],
		"/(app)/[companyId=guid]/offers": [17,[2,4],[3,5]],
		"/(app)/[companyId=guid]/rapport-eligibilite/[id]": [~18,[2,4],[3,5]],
		"/(app)/[companyId=guid]/reports": [~19,[2,4],[3,5]],
		"/(app)/[companyId=guid]/settings": [~20,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/account": [21,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing": [~22,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/invoices": [~23,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/methods": [~24,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/preferences": [~25,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/company": [26,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/security": [27,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/team": [~28,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/subscribe": [29,[2,4],[3,5]],
		"/(app)/[companyId=guid]/[...requestId]": [~10,[2,4],[3,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';